// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';

// import favicon here.
import './resources/images/favicon.png';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; // carousel instances
    _tabbyInstances: any; // tabset instances
    _badgerInstances: any; // badger instances
  }
}

(() => {
  const handleHomePageCarousel = () => {
    const homeBannerInst = window._tnsInstances?.['home-banner'];
    if (homeBannerInst) {
      window.addEventListener('load', () => {
        homeBannerInst.updateSliderHeight();
      });

      // when a modal is open, the scrollbar for the body will not be present, so the carousel height will be calculated for full screen width
      // when the modal is closed, the scrollbar re-appears, making the calculations wrong. Re-calculating the sizes, as soon as the modal is closed
      const modalClose = document.querySelector('.modal-wrapper .main-close');
      modalClose?.addEventListener('click', () => {
        homeBannerInst.updateSliderHeight();
      });

      const slideOneCardContainer = document.querySelector(
        '.home-card-container--slide-1'
      );
      const slideThreeCardContainer = document.querySelector(
        '.home-card-container--slide-3'
      );

      const manageHomeCardDisplay = index => {
        slideOneCardContainer?.classList.remove('active');
        slideOneCardContainer?.classList.remove('active--show');
        slideThreeCardContainer?.classList.remove('active');
        slideThreeCardContainer?.classList.remove('active--show');
        if (index || index === 0) {
          const cardContainer =
            index === 1
              ? slideOneCardContainer
              : index === 3
              ? slideThreeCardContainer
              : '';
          if (cardContainer) {
            cardContainer?.classList.add('active');
            setTimeout(() => {
              cardContainer.classList.add('active--show');
            }, 50);
          }
        }
      };

      homeBannerInst.events.on('transitionEnd', e => {
        const curSlideIndex = e?.displayIndex;
        manageHomeCardDisplay(curSlideIndex);
      });

      manageHomeCardDisplay(1);
    }
  };

  // opens submenu in mobile devices
  // in mobile resolution, clicking on menu item with submenu should show the submenu
  // aaaem-common feature does not fit here, as desktop submenu works on hover and mobile submenu works on click
  // clicking on anchor should redirect the user. aaaem-common does not allow this.
  const handleMobileSubmenuOpen = (menuItems, triggers) => {
    if (menuItems?.length && triggers?.length) {
      menuItems.forEach((el, i) => {
        el.addEventListener('click', e => {
          // if LI is clicked, expand the submenu
          const target = e.target;
          if (target?.classList.contains('emu-navigation__item-parent')) {
            const trigger = triggers[i];
            if (trigger) {
              trigger.classList.add('can-show-submenu');
              trigger.click();
            }
          }
        });
      });

      triggers.forEach((el, i) => {
        el.addEventListener('click', e => {
          if (window.innerWidth < 768) {
            if (triggers[i]?.classList.contains('can-show-submenu')) {
              e.preventDefault();
              triggers[i]?.classList.remove('can-show-submenu');
            }
          }
        });
      });
    }
  };

  // before after page has two carousels, selecting items from one of those should change slide of the other
  const handleBeforeAfterCarousels = () => {
    const beforeAfterInst = window._tnsInstances?.['before-after-carousel'];
    const beforeAfterTriggerInst =
      window._tnsInstances?.['before-after-trigger-carousel'];
    if (beforeAfterInst && beforeAfterTriggerInst) {
      const triggerSliderInfo = beforeAfterTriggerInst.getInfo();
      const slideItems = triggerSliderInfo?.slideItems! as HTMLCollection;
      const slideItemsArr = Array.from(slideItems);

      slideItemsArr.forEach((e, i) => {
        // on load making the first item active
        if (i === 0) {
          e.classList.add('active');
        }

        // when image of the trigger carousel is clicked, getting index of it and moving the main carousel to the corresponding slide
        // when image of the trigger carousel is clicked, making the clicked item active and removing active class for the rest of the slides
        const img = e?.querySelector('.image')!;
        img?.addEventListener('click', () => {
          slideItemsArr.forEach((slideEl, slideElIndex) => {
            if (i === slideElIndex) {
              slideEl.classList.add('active');
            } else {
              slideEl.classList.remove('active');
            }
          });
          beforeAfterInst.goTo(i);
        });
      });
    }
  };

  // in FAQ page, the accordion's toggle all button needs to show expand all and collapse all text based on the state of the accordion.
  const handleFAQAccordion = () => {
    const faqAccId = 'faq-accordion';
    const accordion = document.querySelector(`#${faqAccId}`);
    const toggleAllBtn = document.querySelector(
      '.faq-toggle-all'
    ) as HTMLElement;
    if (accordion && toggleAllBtn) {
      const accInst = window._badgerInstances[faqAccId];
      if (accInst && window.Bus) {
        window.Bus.on('emu-accordion:toggle', e => {
          if (e.id === faqAccId) {
            const openedItems = e.items.filter(item => item.opened);
            // if everything is opened, and if the toggle all button is not toggled(does not show collapse all text), click toggle all with JS
            // if everything is closed, and if the toggle all button is toggled(does not show expand all text), click toggle all with JS
            if (
              (openedItems.length === e.items.length &&
                !toggleAllBtn.classList.contains('js-toggle-on')) ||
              (openedItems.length === 0 &&
                toggleAllBtn.classList.contains('js-toggle-on'))
            ) {
              toggleAllBtn.click();
            }
          }
        });
      }
    }
  };

  const init = () => {
    const home = document.querySelector('#home');
    if (home) {
      handleHomePageCarousel();
    }

    // on clicking on close icon, trigger a click event on the hamburger, to toggle the menu
    const menuClose = document.querySelector('.menu__close');
    const menuTrigger = document.querySelector(
      '.header__menu-trigger'
    ) as HTMLButtonElement;
    if (menuClose && menuTrigger) {
      menuClose.addEventListener('click', e => {
        e.preventDefault();
        menuTrigger.click();
      });
    }

    const mobileSubMenus = document.querySelectorAll(
      '.emu-navigation__item-parent'
    );
    const mobileSubMenusTriggers = document.querySelectorAll(
      '.emu-navigation__item-parent > a'
    );

    if (mobileSubMenus?.length && mobileSubMenusTriggers?.length) {
      handleMobileSubmenuOpen(mobileSubMenus, mobileSubMenusTriggers);
    }

    // in before after page, there are two carousels to be linked and handled. One triggers the other
    const beforeAfter = document.querySelector('#before-after');
    if (beforeAfter) {
      handleBeforeAfterCarousels();
    }

    // for elements that has #isi, adding event listener to scroll to the isi present in the page
    const isiHrefs = document.querySelectorAll('[href="#isi"]');
    const isi = document.querySelector('.cmp-experiencefragment--isi');
    if (isiHrefs?.length && isi) {
      isiHrefs.forEach(elem => {
        elem.addEventListener('click', e => {
          e.preventDefault();
          const isiPos = isi.getBoundingClientRect()?.y || 0;

          // closing menu if it is open
          if (menuTrigger && menuTrigger.classList.contains('js-toggle-on')) {
            menuTrigger.click();
          }

          window.scrollTo({
            top: window.scrollY + isiPos,
            left: 0,
            behavior: 'smooth',
          });
        });
      });
    }

    const faqPage = document.querySelector('#faq-page');
    if (faqPage) {
      handleFAQAccordion();
    }

    if (window.Bus) {
      // in welcomekit page, clicking on menu items should collapse the menu
      window.Bus.on('emu-navigation:clickItem', data => {
        if (data?.navId === 'wk-nav') {
          const menuOpenCTA = document.querySelector(
            'header .aaaem-button.js-toggle-on'
          ) as HTMLElement;
          if (menuOpenCTA) {
            menuOpenCTA.click();
          }
        }
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
